<template lang="pug">
masonry(:cols="{default: 3, 700: 1, 1024: 2}", :gutter="16").testimonials
    .testimonial(v-for="(testimonial, index) in testimonialsHU" :key="testimonial" :class="(index + 1)%2 == 0 ? 'testimonial--even' : ''" :style="`transition-delay: ${300 + (index * 150)}ms`" )
        .item
            p.quote
                template(v-if="$root.$i18n.locale == 'hu'") {{ testimonialsHU[index].quote }}
                template(v-else) {{ testimonialsEN[index].quote }}
            p.person
                span.name
                    template(v-if="$root.$i18n.locale == 'hu'") {{ testimonialsHU[index].name }}
                    template(v-else) {{ testimonialsEN[index].name }}
                    br
                span.work
                    template(v-if="$root.$i18n.locale == 'hu'") {{ testimonialsHU[index].work }}
                    template(v-else) {{ testimonialsEN[index].work }}
</template>

<script>
import {testimonialsEN, testimonialsHU} from '@/i18n/testimonials.js';

export default {
    name: "Testimonials",
    data: () => ({
        testimonialsEN: testimonialsEN,
        testimonialsHU: testimonialsHU,
    })
};
</script>

<style lang="scss">
@import '@/scss/_utilities';

.testimonials {
    margin-top: 32px;
}

.testimonial {
    margin-bottom: 16px;
    opacity: 0;
    transition: $slow-transition;
}

.transition-end .testimonial {
    opacity: 1;
}

.quote {
    margin-bottom: 0;
    border: 1px solid $contentBg-lightgreen;
    border-bottom: 0;
    border-top-right-radius: $rootBorderRadius;
    border-top-left-radius: $rootBorderRadius;
    padding: 16px 16px 16px 56px;
    font-style: italic;
    position: relative;
    
    @include at(tablet) {
        padding-left: 64px;
    }

    &::before {
        content: "“";
        font-family: "Quicksand", sans-serif;
        color: $contentBg-lightgreen;
        font-size: 6rem;
        font-style: normal;
        position: absolute;
        top: calc(-11px + 16px);
        line-height: 90px;
        left: calc(-5px + 16px);

        @include at(tablet) {
            font-size: 8rem;
            top: calc(-1px + 16px);
            left: calc(-7px + 16px);
        }
    }
}

.person {
    background-color: $contentBg-lightgreen;
    color: $bright-text;
    text-align: center;
    padding: 8px;
    line-height: 1.1;
    font-weight: 500;
    border: 1px solid $contentBg-lightgreen;
    border-top: 0;
    border-bottom-left-radius: $rootBorderRadius;
    border-bottom-right-radius: $rootBorderRadius;
    margin-top: -1px;
}

.work {
    font-size: .75rem;
    font-weight: 300;
}

.testimonial--even .quote {
    border-color: $contentBg-brown;
}

.testimonial--even .quote {
    background-color: $contentBg-brown;
    color: $bright-text;

    &::before {
        color: $bright-text;
    }
}

.testimonial--even .person {
    background-color: $contentBg-bright;
    color: $contentBg-brown;
    border-color: $contentBg-brown;
}
</style>
