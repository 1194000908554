<template lang="pug">
.promo-box(:class="`promo-box--${theme}`")
    template(v-if="product == 'lesson60m'")
        .header.from-bottom
            h3.title {{ $t('lessons.pb1.title') }}
            .header-meta
                img(src="../assets/vector/clock.svg" alt="" aria-hidden="true")
                span {{ $t('lessons.pb1.meta1') }}
                span.sep
                img(src="../assets/vector/online.svg" alt="" aria-hidden="true")
                span {{ $t('lessons.pb1.meta2') }}
        .content
            h4.subtitle {{ $t('lessons.pb1.subtitle1') }}
            ul.list
                li.from-left(style="transition-delay:.3s") #[big #[strong {{ $t('lessons.pb1.li1.strong') }}]{{ $t('lessons.pb1.li1.text') }}]
                li.from-left(style="transition-delay:.6s") #[big #[strong {{ $t('lessons.pb1.li2.strong') }}] {{ $t('lessons.pb1.li2.text') }}]
                li.from-left(style="transition-delay:.9s") #[big #[strong {{ $t('lessons.pb1.li3.strong') }}]{{ $t('lessons.pb1.li3.text') }}]
        .footer.from-place(style="transition-delay:1.3s")
            .payment
                img.pplogo(src="../assets/images/lessons/pplogo.webp" alt="PayPal logo")
                h4.price {{ $t('lessons.pb1.price.title') }}
                p {{ $t('lessons.pb1.price.text1') }}#[strong {{ $t('lessons.pb1.price.strong') }}]{{ $t('lessons.pb1.price.text2') }}
            hr
            .policy
                h4 {{ $t('lessons.pb1.policy.title') }}
                p {{ $t('lessons.pb1.policy.text') }}
                    br
                    | #[strong {{ $t('lessons.pb1.policy.strong') }}]
        .clickables.from-place(style="transition-delay:1.6s")
            a.button(:data-cal-link="this.$root.$i18n.locale == 'hu'? 'beatrixguitar/mesterkurzus' : 'beatrixguitar/guitarlesson'" data-cal-namespace="" data-cal-config='{layout":"month_view"}') {{ $t('globals.bookingButton') }}
            h4 {{ $t('lessons.pb1.subtitle2') }}
            a.dark-link(href="#faq") {{ $t('lessons.pb1.anchor') }}
    template(v-if="product == '11ex'")
        .header.from-bottom
            h3.title {{ $t('lessons.pb2.title') }}
            p.subtitle {{ $t('lessons.pb2.subtitle') }}
            .header-meta
                span.author {{ $t('lessons.pb2.author') }}
        .content
            .page-container
                img.page-pic.from-place(src="../assets/images/lessons/11ex_page3.webp" alt="" aria-hidden="true" style="transition-delay:.8s")
                img.page-pic.from-place(src="../assets/images/lessons/11ex_page2.webp" alt="" aria-hidden="true" style="transition-delay:.4s")
                img.page-pic.from-place(src="../assets/images/lessons/11ex_page1.webp" alt="" aria-hidden="true")
                .page-counter.from-place(style="transition-delay:1.2s")
                    span.page-counter__num 23
                    span.page-counter__text {{ $t('lessons.pb2.pages') }}
            p.supp-text.from-bottom(style="transition-delay:1.5s") {{ $t('lessons.pb2.supp1') }}
                a.icon-link(href="https://www.youtube.com/watch?v=zPiFuqT3XqU" target="_blank" rel="noreferrer noopener")
                    span {{ $t('lessons.pb2.supp2') }}
                    img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")
                | {{ $t('lessons.pb2.supp3') }}
        .buy-box.from-bottom(style="transition-delay:1.8s")
            hr
            p.supp-text.supp-text--highlighted {{ $t('lessons.pb2.supp4') }}
            .promo-clickables
                a.button.button--amazon(href="https://www.amazon.com/11-Exercises-Shortcut-Guitar-Technical/dp/6150214303" target="_blank" rel="noreferrer noopener")
                    span.amazon-btn-disc {{ $t('lessons.pb2.amazonBtnDisc') }}
                    span.sr-only {{ $t('lessons.pb2.amazonBtn') }}
                    img(v-svg-inline src="../assets/vector/available_at_amazon.svg" alt="" aria-hidden="true")
                a.button.button--shopify(href="https://beatrixguitarstudio.myshopify.com/products/the-only-exercises-you-need-top-11-exercises-digital-download-workbook-of-my-favourite-11-exercises-tabs-sheet-music?variant=53835456053516" target="_blank" rel="noreferrer noopener")
                    span.sr-only {{ $t('lessons.pb2.title') }}
                    img(v-svg-inline src="../assets/vector/shopify_monotone_white.svg" alt="" aria-hidden="true")

</template>

<script>

export default {
    name: 'PromoBox',
    props: {
        theme: {
            type: String,
            default: 'green'
        },
        product: {
            type: String,
            required: true
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

$promo-bg: #F3F3F3;
.promo-box {
    padding: 16px;
    border-radius: $rootBorderRadius;
    background-color: $promo-bg;
    box-shadow: $button-shadow;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include at(laptopLarge) {
        padding: 24px 32px;
    }
}

.header {
    border-radius: $rootBorderRadius - 2;
    padding: 16px;
    text-align: center;
    margin-bottom: 24px;
}

.title {
    font: 300 1.75rem/1.1 'Montserrat', 'Arial', sans-serif;
    color: $bright-text;
    margin-bottom: 8px;

    @include at(tablet) {
        font-size: 2rem;
    }
}

.header-meta {
    font: 200 1rem/1.1 'Quicksand', 'Arial', sans-serif;
    color: $bright-text;
    display: flex;
    align-items: center;
    justify-content: center;

    @include at(tablet) {
        font-size: 1.25rem;
    }
}

.header-meta img {
    margin-right: 4px;
}

.sep {
    height: 24px;
    width: 1px;
    background-color: $bright-text;
    margin: 0 8px;
}

.content {
    margin-bottom: 24px;
}

.subtitle {
    font: 500 1.2rem/1.1 'Quicksand', 'Quicksand', sans-serif;
    margin-bottom: 16px;

    @include at(tablet) {
        font-size: 1.5rem;
    }
}

.video-box {
    @include at(desktop) {
        height: 351px;
    }
}

.list {
    margin-bottom: 24px;
}

.footer {
    border: 1px solid $contentBg-lightgreen;
    border-radius: 6px;
    padding: 8px 16px;
    margin-bottom: 24px;
}

.payment {
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-template-rows: min-content 1fr;
    grid-gap: 0 8px;
}

.pplogo {
    width: 75%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: center;

    @include at(laptop) {
        width: 100%;
        grid-row: 1/3;
    }
}

.price {
    font-size: 1.125rem;
    grid-column: 2/-1;
    grid-row: 1/2;
    font-weight: 500;
}

.payment p {
    grid-column: 1/-1;
    grid-row: 2/3;
    font-size: .875rem;
    
    @include at(laptop) {
        grid-column: 2/-1;
    }
}

hr {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid;
    border-top-width: 1px;
    border-color: $contentBg-lightgreen;
}

.policy {
    font-size: .875rem;
}

.policy h4 {
    font-size: 1rem;
    font-weight: 500;
}

.clickables {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: auto;

    @include at(tablet) {
        align-items: center;
    }
}

.clickables h4 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 8px;
}

.clickables .button {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button svg {
    height: 24px;
    width: 24px;
    fill: $bright-text;
    margin-left: 4px;
}
</style>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.promo-box--green .header {
    background: linear-gradient(180deg, rgba(16,48,42,1) 0%, rgba(107,121,106,1) 100%);
}

.promo-box--brown .header {
    background: linear-gradient(0deg, $button-bg-active 0%, $button-bg-hover);
}

.promo-box--highlighted {
    background: url('../assets/images/lessons/11exbg.webp') no-repeat center;
    background-size: cover;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        max-width: calc(100% - 42px);
        width: 100%;
        left: 50%;
        top: 0;
        transform: translate(-50%);
        background-color: #00000060;

        @include at(laptop) {
            max-width: calc(100% - 128px);
        }
    }
}

.promo-box--highlighted .content {
    margin-bottom: 0;
}

.promo-box--highlighted .header {
    background: transparent;
    position: relative;
    padding: 16px 0;
}

.promo-box--highlighted .header .title {
    font-size: 2.4rem;
    text-shadow: $text-shadow;
    font-family: "Montserrat";
    letter-spacing: 5px;

    @include at(tablet) {
        font-size: 3.5rem;
        letter-spacing: 10px;
    }

    @include at(laptop) {
        font-size: 4.5rem;
        letter-spacing: 11px;
    }
}

.promo-box--highlighted .header .subtitle {
    color: $bright-text;
    font-family: "Quicksand";
    text-shadow: $text-shadow;
}

.promo-box--highlighted .header .author {
    font-family: "Montserrat";
    text-shadow: $text-shadow;
}

.promo-box--highlighted .supp-text {
    color: $bright-text;
    text-align: center;
    position: relative;
    text-shadow: $text-shadow;
    margin-bottom: 16px;
    padding: 0 12px;
}

.promo-box--highlighted hr {
    max-width: calc(100% - 128px - 48px);
    margin: 0 auto 16px auto;
    width: 100%;
    border-color: $bright-text;
}

.promo-box--highlighted .supp-text--highlighted {
    font-size: 1.2rem;
    font-weight: 500;
}

.promo-clickables {
    position: relative;;
    display: flex;
    justify-content: center;
    flex: 1;
    box-shadow: $button-shadow;
    border-radius: 50%;
    margin: 0 -16px -16px -16px;

    @include at(laptop) {
        margin: 0;
    }
}

.promo-box--highlighted .button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.promo-clickables svg {
    margin: 0;
    width: 100%;
    max-height: 30px;
    height: auto;
    transition: .2s;

    @include at(laptop) {
        max-height: 40px;
    }
}

.promo-box--highlighted .button--amazon {
    $amazon-blue: #232F3E;

    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $amazon-blue;
    position: relative;
    flex-direction: column;

    @include at(laptop) {
        border-top-left-radius: 8px;
        border-top-left-radius: 8px;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 72px;
        height: 100%;
        background-color: $amazon-blue;
        clip-path: polygon(100% -1%, 50% 0%, 50% 100%);
        transform: translateX(25%);
        z-index: 1;
        transition: .2s;
    }

    &:hover {
        background-color: darken($amazon-blue, 15%);
    }

    &:hover::after {
        background-color: darken($amazon-blue, 15%);
    }

    &:active {
        background-color: lighten($amazon-blue, 15%);
    }

    &:active::after {
        background-color: lighten($amazon-blue, 15%);
    }
}

.button--amazon svg {
    transform: scale(1.2);
    transition: .2s;
    z-index: 2;
    max-height: 19px;
    margin-bottom: -5px;

    @include at(tablet) {
        max-height: 25px;
    }
}

.amazon-btn-disc {
    font-size: .6rem;
    margin-bottom: 4px;

    @include at(tablet) {
        font-size: .8rem;
    }
}

.button--amazon:hover svg {
    transform: scale(1.3);
}

.button--amazon:active svg {
    transform: scale(1.2);
    transition: .1s;
}

.promo-box--highlighted .button--shopify {
    $shopify-green: #96BF48;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $shopify-green;
    position: relative;

    @include at(laptop) {
        border-top-right-radius: 8px;
        border-top-right-radius: 8px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 72px;
        height: 100%;
        background-color: $shopify-green;
        clip-path: polygon(50% 0%, 0% 101%, 50% 100%);
        transform: translateX(-25%);
        transition: .2s;
    }

    &:hover {
        background-color: darken($shopify-green, 15%);
    }

    &:hover::before {
        background-color: darken($shopify-green, 15%);
    }

    &:active {
        background-color: lighten($shopify-green, 15%);
    }

    &:active::before {
        background-color: lighten($shopify-green, 15%);
    }
}

.button--shopify svg {
    transform: scale(1.1);
    transition: .2s;
}

.button--shopify:hover svg {
    transform: scale(1.17);
}

.button--shopify:active svg {
    transform: scale(1.1);
    transition: .1s;
}

.page-container {
    position: relative;
    min-height: 300px;
    margin-bottom: 16px;

    @include at(laptopLarge) {
        margin-bottom: 24px;
        min-height: 450px;
    }
}

.page-pic {
    height: 90%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    box-shadow: 0px 30px 20px 5px #00000060;

    @include at(laptopLarge) {
        height: 85%;
    }

    @include at(laptopLarge) {
        &:first-child {
            transform: translate(0%, -48%) rotate(13deg);
        }

        &:nth-child(2) {
            transform: translate(-50%, -58%) rotate(1deg);
        }

        &:nth-child(3) {
            transform: translate(-88%, -48%) rotate(-13deg);
        }

    }
}

@mixin pics-tight {
    &:first-child {
            transform: translate(-35%, -50%) rotate(15deg);
        }

    &:nth-child(2) {
        transform: translate(-48%, -62%) rotate(3deg);
    }

    &:nth-child(3) {
        transform: translate(-65%, -50%) rotate(-15deg);
    }

}

@mixin pics-spread {
    &:first-child {
        transform: translate(0%, -50%) rotate(15deg);
    }

    &:nth-child(2) {
        transform: translate(-50%, -60%) rotate(3deg);
    }

    &:nth-child(3) {
        transform: translate(-100%, -50%) rotate(-15deg);
    }
}

.transition-end .page-pic {
    @include pics-tight();

    @include at(laptop) {
        @include pics-spread()
    }

    @include at(laptopLarge) {
        @include pics-tight()
    }

    @include at(desktop) {
        @include pics-spread()
    }
}


.page-counter {
    position: absolute;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    color: $bright-text;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%);
    background: linear-gradient(180deg, rgba(16,48,42,1) 0%, rgba(107,121,106,1) 100%);
    box-shadow: 0px 5px 7px 3px #00000060;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include at(laptop) {
        bottom: auto;
        top: 50%;
        height: 120px;
        width: 120px;
    }
}

.transition-end .page-counter {
    @include at(laptopLarge) {
        transform: translate(-50%, -50%)
    }
}

.page-counter__num {
    font-size: 3rem;
    font-family: "Quicksand";
    line-height: .8;
}

.page-counter__text {
    font-size: 1rem;
    line-height: 1;

    @include at(laptop) {
        font-size: 1.25rem;
    }
}
</style>