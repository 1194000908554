<template lang="pug">
.videos(:class="{ 'videos--grid': !isSingleItem }")
    template(v-for="video in videos" :key="video.id")
        .video-box
            iframe.video(v-if="$globals.videoPlaying == video.id" :src="video.embedUrl" frameborder="0" allowfullscreen allow="autoplay")
            button.preview(v-else @click.prevent="playController(video.id)")
                .thumbnail-container
                    picture
                        source(media="(min-width:726px)" :srcset="video.thumbnails.maxres")
                        source(media="(min-width:426px)" :srcset="video.thumbnails.high")
                        img.thumbnail(:src="video.thumbnails.medium" :alt="video.title")
                    //-p.title
                        span {{ video.title }}
                    svg.play-icon(version="1.1", xmlns="http://www.w3.org/2000/svg", xlink="http://www.w3.org/1999/xlink", viewBox="0 0 459 459", space="preserve")
                        g
                            g
                            path(d="M229.5,0C102.751,0,0,102.751,0,229.5S102.751,459,229.5,459S459,356.249,459,229.5S356.249,0,229.5,0z M310.292,239.651l-111.764,76.084c-3.761,2.56-8.63,2.831-12.652,0.704c-4.022-2.128-6.538-6.305-6.538-10.855V153.416c0-4.55,2.516-8.727,6.538-10.855c4.022-2.127,8.891-1.857,12.652,0.704l111.764,76.084c3.359,2.287,5.37,6.087,5.37,10.151C315.662,233.564,313.652,237.364,310.292,239.651z")
</template> 
<script>
import getVideo from '@/js/mixins/getVideo.js'
import getPlaylist from '@/js/mixins/getPlaylist.js'

export default {
    name: 'Videos',
    data() {
        return {
            playing: null,
        }
    },
    mixins: [getVideo, getPlaylist],
    props: {
        videoId: String,
        playlistId: String,
        maxResults: {
            type: String,
            default: "4"
        }
    },
    mounted() {
        if(this.videoId) {
            this.getVideoData(this.videoId)
        } else {
            this.getPlaylistData(this.playlistId, this.maxResults)
        }
    },
    computed: {
        videos() {
            let videos = new Array()
            let responseData = this.videoId ? this.videoData : this.playlistData
            Object.keys(responseData).forEach((item) => {
                let response = responseData[item]
                let video = new Object()
                video.id = response.id
                video.url = `https://www.youtube.com/watch?v=${response.id}`
                video.embedUrl = `https://www.youtube.com/embed/${response.id}?autoplay=1'`
                video.title = response.title
                video.thumbnails = {
                    "default": response.thumbnails?.default ? response.thumbnails.default : require(`@/assets/images/thumbnail-fallbacks/tn-default.jpeg`),
                    "medium": response.thumbnails?.medium ? response.thumbnails.medium : require(`@/assets/images/thumbnail-fallbacks/tn-medium.jpeg`),
                    "high": response.thumbnails?.high ? response.thumbnails.high : require(`@/assets/images/thumbnail-fallbacks/tn-high.jpeg`),
                    "standard": response.thumbnails?.standard ? response.thumbnails.standard : require(`@/assets/images/thumbnail-fallbacks/tn-standard.jpeg`),
                    "maxres": response.thumbnails?.maxres ? response.thumbnails.maxres : require(`@/assets/images/thumbnail-fallbacks/tn-maxres.jpeg`)
                }
                videos.push(video)
            })
            //console.log('videos', videos)
            return videos
        },
        isSingleItem() {
        return Object.keys(this.videos).length === 1;
        }
    },
    methods: {
        playController(videoId) {
            console.log(this.$globals.videoPlaying)

            this.$globals.videoPlaying = videoId
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.videos--grid {
    display: grid;
    gap: 16px;
    width: 100%;

    @include at(tablet) {
        grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
    }
}

.preview {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 100%;
}

.video-box {
    display: flex;
    background-color: black;
    overflow: hidden;
    border-radius: $rootBorderRadius;
}

.thumbnail-container {
    overflow: hidden;
}

.video {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.thumbnail {
    display: block;
    width: 100%;
    transition: $quick-transition;
    box-shadow: inset 0px 0px 25px 5px #634548;

    &:hover {
        transition: $quick-transition;
    }
}

.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 64px;
    fill: white;
    opacity: .8;
    transition: $quick-transition;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.25);
    border-radius: 50%;
}

.title {
    padding: 8px 16px;
    transition: $quick-transition;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: $bright-text;
    font: 300 1rem/1.4 'Quicksand', 'Arial', sans-serif;
    transition: $quick-transition;
    margin: 0;
    opacity: 0;
    //text-shadow: $text-shadow;

    &::before {
        background: linear-gradient(to bottom, transparent 70%, #00000080 100%), linear-gradient(to top, transparent 70%, #00000080 100%);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: $quick-transition;
    }
}

.title:hover::before {
    opacity: 1;
}

.title span {
    padding: 4px 8px;
    background-color: $button-bg;
    transition: $quick-transition;
    z-index: 1;
}

.preview:hover .thumbnail {
    filter: brightness(.7);
}

.preview:hover .play-icon {
    opacity: .9;
}

.preview:hover .title {
    opacity: 1;
}

.preview:hover .title span {
    background-color: $button-bg-hover;
}

.preview:active .title span {
    background-color: $button-bg-active;
}
</style>
